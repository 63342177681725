import React, { useEffect } from "react"

import FormInput from "../../Elements/Form/FormInput"
import Button from "../../Elements/Button"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons"

const MedicineQuantityInput = ({
  qty,
  setFieldValue,
  changeQuantity,
  initialQuantity,
  selectedMedicine,
}) => {
  useEffect(() => {
    if (qty !== initialQuantity) changeQuantity(setFieldValue, initialQuantity)
  }, [selectedMedicine?.productTitle])

  return (
    <FormInput
      hasAddons
      addonLeft={
        <Button
          onClick={() =>
            changeQuantity(
              setFieldValue,
              selectedMedicine?.maxQuantity,
              parseInt(qty) - 1
            )
          }
          color="danger"
        >
          <FontAwesomeIcon icon={faMinus} />
        </Button>
      }
      addonRight={
        <Button
          onClick={() =>
            changeQuantity(
              setFieldValue,
              selectedMedicine?.maxQuantity,
              parseInt(qty) + 1
            )
          }
          color="success"
        >
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      }
      name="qty"
      inputmode="numeric"
      className="has-text-centered"
      type="number"
      pattern="[0-9]"
      min={1}
      max={selectedMedicine?.maxQuantity || 999}
      hideOptional
      setFieldValue={setFieldValue}
      onKeyPress={(event) => {
        if (isNaN(event.key)) event.preventDefault()
      }}
    />
  )
}

export default MedicineQuantityInput
