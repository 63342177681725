import React, { useContext, useState, useEffect } from "react"
import { navigate } from "gatsby"
import classNames from "classnames"

import MedicinesCard from "../Cart/MedicinesCard"
import DocumentsCard from "../Cart/DocumentsCard"
import CouponSection from "../Cart/CouponSection"
import CouponDiscountSection from "../Cart/CouponDiscountSection"
import OrderSubtotalSection from "../Cart/OrderSubtotalSection"
import Message from "../Elements/Message"
import Button from "../Elements/Button"

import { AppContext } from "../../context/AppContext"
import { getCouponDiscountAmount } from "./services/coupon"
import { generateDeliveryFee } from "./services/computations"

import styles from "./utils/epharmacy.module.scss"

const Cart = ({ open }) => {
  const { state, dispatch } = useContext(AppContext)

  // const [isAccumulatedPriceValid, setIsAccumulatedPriceValid] = useState(0)
  const { epharmacy, documents } = state

  const {
    medicines,
    coupon,
    hasPartnerRequest,
    isMedGrocerWellnessPatient,
  } = epharmacy

  let isWellnessPatient =
    isMedGrocerWellnessPatient && coupon?.code === "MGWELL23"

  const hasNoMeds =
    medicines.length === 0 && !hasPartnerRequest && !isWellnessPatient
  const [isLoading, setIsLoading] = useState(false)

  // useEffect(() => {
  //   const totalPrice = parseFloat(accumulatePrice(medicines))
  //   const MINIMUM_BASKET_VALUE = 300.0
  //   setIsAccumulatedPriceValid(totalPrice < MINIMUM_BASKET_VALUE)
  // }, [medicines])

  const handleRedirectToDeliveryDetails = () => {
    setIsLoading(true)
    // hide my cart modal if on mobile
    dispatch({ type: "HIDE_MODAL" })
    navigate("/epharmacy/checkout")
  }

  const hasNoPrescription =
    documents?.length === 0 &&
    medicines?.filter((medicine) => medicine?.drugRxLevel === "N")?.length > 0

  return (
    <div className="mb-3">
      <h3 className="has-text-centered has-text-primary mt-0 is-hidden-mobile">
        My Cart
      </h3>
      <MedicinesCard open={open?.medicinesCard} />
      <CouponSection />
      {coupon && medicines.length > 0 && (
        <CouponDiscountSection
          couponDiscount={getCouponDiscountAmount(
            coupon,
            medicines,
            generateDeliveryFee({
              coupon: coupon,
              couponCode: coupon?.code,
              addressType: state?.epharmacy?.deliveryAddress?.addressType,
            })
          )}
          medicines={medicines}
          coupon={coupon && coupon.code}
        />
      )}
      {medicines.length > 0 && (
        <OrderSubtotalSection
          medicines={medicines}
          couponDiscount={getCouponDiscountAmount(
            coupon,
            medicines,
            generateDeliveryFee({
              couponCode: coupon?.code,
              coupon: coupon,
            })
          )}
          province={epharmacy?.deliveryAddress?.province?.value}
          couponCode={coupon && coupon.code}
          coupon={coupon}
          addressType={state?.epharmacy?.deliveryAddress?.addressType}
        />
      )}
      {hasNoPrescription && (
        <div className="mt-1">
          <Message>A prescription is needed to process your request</Message>
        </div>
      )}
      <div
        className={classNames(
          "is-hidden-desktop",
          styles["cart__checkoutButton"]
        )}
      >
        {hasNoPrescription ? (
          <Button
            className={classNames(`button is-primary is-fullwidth mt-1`, {
              "has-background-grey": hasNoMeds,
            })}
            disabled={
              hasNoMeds ||
              state?.documents?.filter((document) => !document?.token)?.length >
                0
            }
            onClick={() => {
              navigate("/epharmacy/upload")
            }}
            isLoading={isLoading}
          >
            <span className="is-size-6">Upload a Prescription</span>
          </Button>
        ) : (
          <Button
            className={classNames(`button is-primary is-fullwidth mt-1`, {
              "has-background-grey": hasNoMeds,
            })}
            disabled={
              hasNoMeds ||
              state?.documents?.filter((document) => !document?.token)?.length >
                0
            }
            onClick={handleRedirectToDeliveryDetails}
            isLoading={isLoading}
          >
            <span className="is-size-6">
              {!hasNoMeds ? "Checkout" : "Your cart cannot be empty"}
            </span>
          </Button>
        )}
      </div>
    </div>
  )
}

export default Cart
