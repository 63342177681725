import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import classNames from "classnames"

import { AppContext } from "../../../context/AppContext"
import FilePreview from "../../Upload/FilePreview"
import Loading from "../../Elements/Loading"

import styles from "../utils/cart.module.scss"

const DocumentsCardRow = ({
  fileName,
  oldFileName,
  handleDeleteDocument,
  index,
  file,
}) => {
  const data = useStaticQuery(graphql`
    {
      pdfThumbnail: file(relativePath: { eq: "pdf.png" }) {
        childImageSharp {
          fixed(width: 48) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  const pdfThumbnail = data.pdfThumbnail.childImageSharp.fixed

  const { dispatch } = useContext(AppContext)
  let oldFileNameArray = oldFileName.split(".")
  let fileType = oldFileNameArray[oldFileNameArray.length - 1] || "file"

  const imageThumbnail =
    fileType === "pdf" ? (
      <Img fixed={pdfThumbnail} />
    ) : (
      <img
        src={file.path}
        className={classNames(
          "image is-28x28",
          styles["documentRow__imgThumbnail"]
        )}
        alt="attachment"
      />
    )

  const handleFileClick = () =>
    dispatch({
      type: "SHOW_MODAL",
      payload: {
        content: <FilePreview file={file} isPdf={fileType === "pdf"} />,
      },
    })

  return (
    <article className={classNames("media")}>
      {!!file?.token ? (
        <figure
          onClick={handleFileClick}
          className={classNames("media-left px-1")}
        >
          {imageThumbnail}
        </figure>
      ) : (
        <div className="px-1">
          <Loading type="circle" />
        </div>
      )}
      <div onClick={handleFileClick} className="media-content is-size-7">
        <div className="subtitle">
          {fileName}
          {!!file?.token ? (
            <p className="help">{oldFileName}</p>
          ) : (
            <p className="help">Uploading file...</p>
          )}
        </div>
      </div>
      <div className="media-right">
        <a
          className={classNames("delete", styles["closeButton"])}
          onClick={() => handleDeleteDocument(index)}
          type="button"
        />
      </div>
    </article>
  )
}

export default DocumentsCardRow
